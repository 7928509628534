<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <!-- Add Search -->

    <b-modal :no-close-on-backdrop="true" ref="taskChatModal" @hidden="onTaskModalClose" hide-footer>
      <div class="AliifChat-chatbox">
        <div class="chat-header bg-primary">
          <h5 style="color: #fff" class="m-0">Switz</h5>
        </div>
        <div class="chat-messages">
          <b-row v-for="(c, index) in taskChat" :key="'tc_' + index">
            <div class="message ml-2 mb-2">
              <div class="user-info">
                <span class="user-name">
                  {{ `${c.user_firstname} ${c.user_lastname}` }}
                </span>
                <span class="user-type">
                  {{ c.user_type_tag || c.user_type_name }}
                </span>
              </div>
              <div class="message-text">
                <span class="message-content">{{ c.message }}</span>
                <sub class="message-timestamp">{{
                  moment(c.created_at).format("DD-MM-YYYY - hh:mm A")
                }}</sub>
              </div>
            </div>
          </b-row>
        </div>
        <div v-if="currentTask && currentTask.tk_status !== 'complete'" class="chat-input">
          <b-row class="mb-3 mx-0">
            <b-col cols="9">
              <b-form-textarea type="textarea" placeholder="Enter Message" class="border-0" cols="5" @keypress="(e) => {
                if (e.key === 'Enter') {
                  sendTaskMessage();
                  e.preventDefault();
                }
              }
                " maxlength="5000" v-model="chatMessage" />
            </b-col>
            <b-col cols="3">
              <button @click="sendTaskMessage" class="btn btn-primary">
                SEND
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>

    <div style="height: inherit">
      <div class="body-content-overlay" :class="{ show: mqShallShowLeftSidebar }"
        @click="mqShallShowLeftSidebar = false" />
      <div class="todo-app-list" style="top: -80px">
        <!-- App Searchbar Header -->
        <div class="app-fixed-search d-flex align-items-center">
          <!-- Toggler -->
          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
          </div>

          <!-- Searchbar -->
          <div class="d-flex align-content-center justify-content-between w-100 mr-1">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQuery" placeholder="Search task" />

              <!-- @input="updateRouteQuery" -->
            </b-input-group>
          </div>
          <div>
            <b-form-checkbox v-model="taskFilters.showOverdue" name="check-button" style="width: 150px"
              class="pt-1 mr-1" switch @input="fetchTasks()">
              Show Overdue
            </b-form-checkbox>
          </div>
        </div>
        <div class="bg-white px-2 py-1">
          <b-row class="mb-1 d-flex justify-content-center">
            <b-col cols="4">
              <v-select placeholder="User" class="" v-model="taskFilters.tk_user_id" :options="userOptions"
                :reduce="(assignor) => assignor.user_id" label="fullName"></v-select>
            </b-col>
            <b-col cols="3">
              <flat-pickr v-model="taskFilters.start_date" class="form-control" placeholder="Start Date" />
            </b-col>
            <b-col cols="3">
              <flat-pickr v-model="taskFilters.deadline_date" class="form-control" placeholder="Deadline Date" />
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-center">
            <b-col cols="2" v-if="currentPage !== 'tasks-completed'">
              <v-select placeholder="Status" v-model="taskFilters.tk_status" :reduce="(status) => status.value"
                :options="[
                  { label: 'Pending', value: 'pending' },
                  { label: 'In Progress', value: 'in_progress' },
                ]"></v-select>
            </b-col>
            <b-col :cols="`${currentPage !== 'tasks-completed' ? '3' : '5'}`">
              <v-select placeholder="Student" class="" v-model="taskFilters.student_id" :options="studentOptions"
                :reduce="(student) => student.user_id" label="user_name"></v-select>
            </b-col>

            <b-col cols="3">
              <v-select placeholder="Sort By" v-model="taskFilters.sortBy" :options="sortByOptions"
                :reduce="(sortByOption) => sortByOption.value" label="label"></v-select>
            </b-col>
            <b-col cols="2">
              <div class="d-flex">
                <button class="btn btn-primary w-50 mr-1" @click="fetchTasks()">
                  Find
                </button>
                <button class="btn btn-primary w-50" @click="resetFilters()">
                  Reset
                </button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div></div>
        <!-- Todo List -->
        <!-- <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="todo-task-list-wrapper list-group scroll-area"
        > -->
        <div class="table-card">
          <b-table :items="tasks" :fields="taskFields" @row-clicked="handleRowClick" responsive class="table-card">
            <template #cell(tk_id)="data">
              <div style="width: max-content" class="d-flex">
                {{ data.index + 1 }}
                <b-badge pill :variant="`light-danger`" class="text-capitalize ml-1"
                  v-if="isOverdue(data.item.tk_status, data.item.deadline_date)">
                  overdue
                </b-badge>
              </div>
            </template>

            <template #cell(tk_status)="data">
              <div style="width: 175px">
                <v-select v-if="
                  currentPage === 'tasks' || currentPage === 'tasks-important'
                " placeholder="Status" :clearable="false" v-model="data.item.tk_status"
                  :reduce="(status) => status.value" :options="[
                    { label: 'Pending', value: 'pending' },
                    { label: 'In Progress', value: 'in_progress' },
                    { label: 'Complete', value: 'complete' },
                  ]" @input="(value) =>
                    addTask({
                      ...data.item,
                      tk_status: value ? value : 'pending',
                    })
                    ">
                  <template #selected-option="{ value }">
                    <b-badge :variant="`light-${value === 'in_progress'
                      ? 'primary'
                      : value === 'complete'
                        ? 'success'
                        : 'danger'
                      }`">{{ statusMapper[data.item.tk_status] }}</b-badge>
                  </template>
                </v-select>
                <b-badge v-else :variant="`light-${data.item.tk_status === 'in_progress'
                  ? 'primary'
                  : data.item.tk_status === 'complete'
                    ? 'success'
                    : 'danger'
                  }`">{{ statusMapper[data.item.tk_status] }}</b-badge>
              </div>
            </template>

            <template #cell(start_date)="data">
              <div style="width: max-content">
                <p>
                  {{ moment(data.item.start_date).format("DD MMM YYYY") }}
                </p>
              </div>
            </template>

            <template #cell(tk_assignee)="data">
              <div style="width: max-content">
                <p>
                  {{ data.item.assignee_user_name }}
                </p>
              </div>
            </template>

            <template #cell(tk_assignor)="data">
              <div style="width: max-content">
                <p>
                  {{ data.item.assignor_user_name }}
                </p>
              </div>
            </template>

            <template #cell(tk_student)="data">
              <div style="width: max-content">
                <p>
                  {{ data.item.student_user_name }}
                </p>
              </div>
            </template>

            <template #cell(tk_application)="data">
              <div style="width: max-content">
                <h5>{{ data.item.university_name }}</h5>
                <p>
                  {{ data.item.course_name }}
                </p>
              </div>
            </template>

            <template #cell(chat)="data">
              <div style="width: max-content">
                <feather-icon style="cursor: pointer" icon="MessageSquareIcon" size="20"
                  @click.prevent.stop="showTaskChatModal(data.item)" />
              </div>
            </template>

            <template #cell(deadline_date)="data">
              <div style="width: max-content">
                <p>
                  {{ moment(data.item.deadline_date).format("DD MMM YYYY") }}
                </p>
              </div>
            </template>

            <template #cell(assignee_comments)="data">
              <div style="width: max-content">
                <b-form-textarea @input="saveAssigneeComments(data.item)" v-if="
                  currentPage === 'tasks' || currentPage === 'tasks-important'
                " id="textarea" v-model="data.item.assignee_comments" placeholder="Your Remarks" rows="3"
                  max-rows="6"></b-form-textarea>
                <p v-else>{{ data.item.assignee_comments || "-" }}</p>
              </div>
            </template>

            <template #cell(tk_priority)="data">
              <div style="width: max-content">
                <b-badge pill :variant="`light-${resolveTagVariant(data.item.tk_priority)}`" class="text-capitalize">
                  {{ data.item.tk_priority }}
                </b-badge>
              </div>
            </template>
          </b-table>
        </div>
        <!-- </vue-perfect-scrollbar> -->
      </div>

      <!-- Add Task Handler -->
      <todo-task-handler-sidebar v-model="isTaskHandlerSidebarActive" :allow-edit="allowEdit" :task="task"
        :clear-task-data="clearTaskData" :fetch-tasks="fetchTasks" :add-task="addTask" />

      <!-- Left Sidebar -->
      <portal to="content-renderer-sidebar-left">
        <todo-left-sidebar :task-tags="taskTags" :allow-edit="allowEdit"
          :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive" :class="{ show: mqShallShowLeftSidebar }"
          @close-left-sidebar="mqShallShowLeftSidebar = false" @change-allow-edit="allowEdit = true"
          @change-current-priority="(priority) => {
            currentPriority = priority;
            fetchTasks();
          }
            " @change-current-page="(name) => {
              currentPage = name;
              fetchTasks();
            }
              " :currentPage="currentPage" :currentPriority="currentPriority" />
      </portal>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BCard,
  BRow,
  BCol,
  BTable,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { formatDate, avatarText } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import TodoLeftSidebar from "./TodoLeftSidebar.vue";
import TodoTaskHandlerSidebar from "./TodoTaskHandlerSidebar.vue";
import { onBeforeMount, onMounted } from "@vue/runtime-core";
import CommonServices from "@/apiServices/CommonServices";
import moment, { now } from "moment";
import { useRoute } from "vue-router";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormTextarea,
    BBadge,
    BAvatar,
    draggable,
    BCard,
    BRow,
    BCol,
    BTable,
    vSelect,
    VuePerfectScrollbar,
    flatPickr,
    MultiStateSwitch,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },

  setup() {
    const tasks = ref([]);
    const searchQuery = ref("");

    const blankTask = {
      id: null,
      title: "",
      dueDate: new Date(),
      description: "",
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    };
    const task = ref(JSON.parse(JSON.stringify(blankTask)));
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask));
    };

    const statusMapper = {
      pending: "Pending",
      in_progress: "In Progress",
      complete: "Complete",
    };

    const addTask = async (task) => {
      const res = await CommonServices.saveTask({
        tk_id: task.tk_id ? task.tk_id : null,
        tk_assignor_id: task.tk_assignor_id,
        tk_student_id: task.tk_student_id,
        tk_assignee_id: task.tk_assignee_id,
        tk_application_id: task.tk_application_id,
        tk_title: task.tk_title,
        tk_description: task.tk_description,
        tk_priority: task.tk_priority,
        start_date: task.start_date,
        deadline_date: task.deadline_date,
        assigned_date: task.assigned_date,
        is_active: task.is_active,
        tk_status: task.tk_status,
        assignee_comments: task.assignee_comments,
        is_important: task.is_important,
      });

      fetchTasks();

      isTaskHandlerSidebarActive.value = false;
      return res.data.status;
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    function handleRowClick(e) {
      handleTaskClick(e);
    }

    let assigneeCommentDebounce = ref(null);

    async function saveAssigneeComments(task) {
      if (assigneeCommentDebounce.value)
        clearTimeout(assigneeCommentDebounce.value);

      assigneeCommentDebounce.value = setTimeout(async () => {
        try {
          if (addTask(task)) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Comment Added",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        } catch (err) {
          console.error("Error in saving comment", err);
        }
      }, 1200);
    }

    const isTaskHandlerSidebarActive = ref(false);

    const taskTags = [
      {
        title: "All",
        color: "primary",
        value: null,
      },
      {
        title: "Low",
        color: "success",
        value: "low",
      },
      {
        title: "Medium",
        color: "warning",
        value: "medium",
      },
      {
        title: "High",
        color: "danger",
        value: "high",
      },
    ];

    const taskFields = ref([
      { key: "tk_id", label: "#", sortable: false },
      { key: "tk_status", label: "In Progress", sortable: false },
      { key: "tk_student", label: "Student", sortable: false },
      { key: "tk_title", label: "Subject", sortable: false },
      { key: "start_date", label: "Start Date", sortable: false },
      { key: "deadline_date", label: "Deadline", sortable: false },
      { key: "tk_priority", label: "Priority", sortable: false },
      { key: "tk_application", label: "Application", sortable: false },
      { key: "chat", label: "chat", sortable: false },
      { key: "assignee_comments", label: "Remarks", sortable: false },
      // { key: "tk_assignee", label: "Assignee", sortable: false },
      // { key: "tk_assignor", label: "Assignor", sortable: false },
    ]);

    const resolveTagVariant = (tag) => {
      if (tag === "low") return "success";
      if (tag === "medium") return "warning";
      if (tag === "high") return "danger";
      return "primary";
    };

    const resolveAvatarVariant = (tags) => {
      if (tags.includes("high")) return "primary";
      if (tags.includes("medium")) return "warning";
      if (tags.includes("low")) return "success";
      return "primary";
    };

    const currentPage = ref("tasks"); // [tasks-assigned, tasks, tasks-important, tasks-completed, tasks-deleted]
    const currentPriority = ref(null); // [null, 'high', 'low', 'medium']

    const taskFilters = ref({
      tk_status: null,
      university_id: null,
      student_id: null,
      start_date: null,
      deadline_date: null,
      tk_user_id: null,
      sortBy: null,
      showOverdue: false,
    });

    const allowEdit = ref(true);

    const handleTaskClick = (taskData) => {
      task.value = taskData;
      if (currentPage.value == "tasks-assigned") {
        allowEdit.value = true;
      } else {
        allowEdit.value = false;
      }
      isTaskHandlerSidebarActive.value = true;
    };

    const fetchTasks = async () => {
      let res;
      if (currentPage.value === "tasks-assigned") {
        taskFields.value.splice(1, 0, {
          key: "tk_status",
          label: "In Progress",
          sortable: false,
        });
        taskFields.value = taskFields.value.filter(
          (task) => task.key !== "tk_assignor"
        );
        taskFields.value.push({
          key: "tk_assignee",
          label: "Assignee",
          sortable: false,
        });

        res = await CommonServices.getAllAssignedTasks({
          ...taskFilters.value,
          currentPriority: currentPriority.value,
          searchQuery: searchQuery.value,
        });
      } else if (currentPage.value === "tasks") {
        taskFields.value.splice(1, 0, {
          key: "tk_status",
          label: "In Progress",
          sortable: false,
        });
        taskFields.value = taskFields.value.filter(
          (task) => task.key !== "tk_assignee"
        );
        taskFields.value.push({
          key: "tk_assignor",
          label: "Assignor",
          sortable: false,
        });
        res = await CommonServices.getAllTasks({
          ...taskFilters.value,
          currentPriority: currentPriority.value,
          searchQuery: searchQuery.value,
        });
      } else if (currentPage.value === "tasks-important") {
        taskFields.value.splice(1, 0, {
          key: "tk_status",
          label: "In Progress",
          sortable: false,
        });
        taskFields.value = taskFields.value.filter(
          (task) => task.key !== "tk_assignee"
        );
        taskFields.value.push({
          key: "tk_assignor",
          label: "Assignor",
          sortable: false,
        });
        res = await CommonServices.getAllTasks({
          isImportant: true,
          ...taskFilters.value,
          currentPriority: currentPriority.value,
          searchQuery: searchQuery.value,
        });
      } else if (currentPage.value === "tasks-completed") {
        taskFields.value = taskFields.value.filter(
          (task) => task.key !== "tk_assignee"
        );
        taskFields.value.push({
          key: "tk_assignor",
          label: "Assignor",
          sortable: false,
        });
        res = await CommonServices.getAllTasks({
          isCompleted: true,
          ...taskFilters.value,
          currentPriority: currentPriority.value,
          searchQuery: searchQuery.value,
        });
      } else if (currentPage.value === "tasks-deleted") {
        res = await CommonServices.getAllAssignedTasks({
          onlyDeleted: true,
          ...taskFilters.value,
          currentPriority: currentPriority.value,
          searchQuery: searchQuery.value,
        });
      }

      tasks.value = res.data.data.data;
    };

    fetchTasks();

    function resetFilters() {
      searchQuery.value = "";
      taskFilters.value = {
        tk_status: null,
        university_id: null,
        student_id: null,
        start_date: null,
        deadline_date: null,
        sortBy: null,
        showOverdue: false,
      };
      fetchTasks();
    }

    function isOverdue(tk_status, deadline_date) {
      const currentDate = moment();
      const deadlineDate = moment(deadline_date);
      if (tk_status != "complete") {
        if (currentDate.isAfter(deadlineDate)) {
          return true;
        }
      }
      return false;
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = (taskData) => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted;
      updateTask(taskData);
    };

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const universityOptions = ref([]);
    const studentOptions = ref([]);
    const userOptions = ref([]);
    const sortByOptions = [
      { label: "Start Date (Asc)", value: "start_date_asc" },
      { label: "Start Date (Desc)", value: "start_date_dsc" },
      { label: "Due Date (Asc)", value: "due_date_asc" },
      { label: "Due Date (Desc)", value: "due_date_dsc" },
    ];

    function getAllStudents() {
      CommonServices.getAssignedStudents()
        .then((response) => {
          if (response.data.status) {
            studentOptions.value = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting getAllStudents", err);
        });
    }

    function getAllUniversities() {
      CommonServices.getAllUniversities()
        .then((response) => {
          if (response.data.status) {
            universityOptions.value = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting getAllStudents", err);
        });
    }

    function getAllAssignees() {
      CommonServices.getTaskUsers()
        .then((response) => {
          if (response.data.status) {
            userOptions.value = response.data.data;
          }
        })
        .catch((err) => {
          console.log("Error in getting fetchAllAssignees", err);
        });
    }

    const taskChat = ref([]);
    const currentTaskId = ref(null);
    const currentTask = ref({});
    const chatMessage = ref("");
    const getTaskChatInterval = ref(null);

    async function showTaskChatModal(tk) {
      this.$refs["taskChatModal"].show();

      currentTaskId.value = tk.tk_id;
      currentTask.value = tk;

      await loadTaskChat();

      getTaskChatInterval.value = setInterval(taskChat.value, 5000);
    }

    async function loadTaskChat() {
      try {
        const res = await CommonServices.getTaskChat({
          task_id: currentTaskId.value,
        });

        taskChat.value = res.data.data;
      } catch (error) {
        console.error("Error in loadTaskChat", error);
      }
    }

    function onTaskModalClose() {
      clearInterval(getTaskChatInterval);
      this.$refs["taskChatModal"].hide();
    }

    async function sendTaskMessage() {
      await CommonServices.sendTaskChat({
        task_id: currentTaskId.value,
        message: chatMessage.value,
      });
      chatMessage.value = "";
      await this.loadTaskChat();
    }

    getAllStudents();
    getAllUniversities();
    getAllAssignees();

    return {
      task,
      tasks,
      taskFields,
      addTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      moment,
      allowEdit,
      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,
      handleRowClick,

      // Filters
      formatDate,
      avatarText,
      resetFilters,

      // Single Task isCompleted update
      updateTaskIsCompleted,
      saveAssigneeComments,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      currentPage,
      currentPriority,
      taskFilters,
      universityOptions,
      studentOptions,
      userOptions,
      sortByOptions,
      isOverdue,
      statusMapper,

      // task chat
      loadTaskChat,
      showTaskChatModal,
      onTaskModalClose,
      sendTaskMessage,
      taskChat,
      chatMessage,
      currentTask,
    };
  },
};
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "../../../theme-variables/theme.module.scss";

.AliifChat-chatbox {
  width: 460px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  overflow-x: hidden;
}

.chat-header {
  background-color: #00e87c;
  color: #fff;
  text-align: center;
  padding: 14px 0;
  font-weight: bold;
}

.chat-messages {
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;
}

.message {
  margin-bottom: 10px;
}

.user-info {
  margin-right: 10px;
}

.user-name {
  font-weight: bold;
}

.user-type {
  font-size: 12px;
  color: #777;
}

.message-text {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 8px 12px;
  word-break: break-word;
}

.message-content {
  word-wrap: break-word;
}

.message-timestamp {
  font-size: 7px;
  padding-left: 5px;
  color: #777;
}

.chat-input {
  padding-top: 10px;
  margin-left: 15px;
  margin-right: 15px;

  border-top: 1px solid $primary;
}

.send-button {
  background-color: #00e87c;
  color: #5367ff;
  font-weight: bold;
  border: 1px solid #5367ff;
  padding: 8px 12px;
  border-radius: 20px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #5367ff;
  color: #00e87c;
}

.table-card {
  height: calc(100% - 80px);
  background-color: white;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
